import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  HStack,
  IconButton,
  ModalBody,
  ModalContent,
  PinInput,
  PinInputField,
  PinInputFieldProps,
  Text,
  VStack
} from '@chakra-ui/react'
import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { HabbitTextIcon } from '@icons/habbit-text-icon'
import RewardTiersTable from '@components/reward-points/reward-tiers-table'
import { REWARD_TIERS } from '@constants/reward.constant'
import { AvocadoUser } from '@lib/context/account-context'
import axios from 'axios'
import { AVOCADO_CONSTANT } from '@lib/constants'

const pinInputFieldProps: PinInputFieldProps = {
  style: { fontSize: '30px' },
  bg: '#CFC8B8',
  borderRadius: 0,
  border: 0,
  w: 20,
  h: 20,
  type: 'password'
}

interface AdvocadoModalContentPropsType {
  avocado?: AvocadoUser
  isLoading: boolean
  error?: any
  setPin?: (value: string) => void
  isPDPA?: boolean
  onCloseModal: () => void
}

const AdvocadoModalContent: React.FC<AdvocadoModalContentPropsType> = ({
  avocado,
  isLoading,
  error,
  setPin,
  isPDPA = true,
  onCloseModal
}) => {
  const [isShowPincode, setIsShowPincode] = useState(false)
  const [pdpa, setPDPA] = useState('')

  const loadPDPA = async () => {
    if (!avocado?.token) return

    const { data } = await axios.get(AVOCADO_CONSTANT.get_pdpa, {
      headers: {
        Authorization: 'Bearer ' + avocado?.token || ''
      }
    })

    if (data.status === 'success') {
      setPDPA(data.data.pdpaContent)
    }
  }

  useEffect(() => {
    console.log('Load PDPA Content')
    loadPDPA()
  }, [avocado])

  return (
    <ModalContent bg={'bg'} py={10}>
      <Container maxW={'2000px'}>
        <HStack w={'100%'} justifyContent={'end'}>
          <IconButton
            aria-label="close-button"
            icon={<SmallCloseIcon w={16} h={16} color={'red'} />}
            bg={'transparent'}
            _hover={{
              bg: 'transparent'
            }}
            onClick={onCloseModal}
          />
        </HStack>
      </Container>
      <ModalBody py={20} bg={'bg'} px={0}>
        <Container maxW={'1250px'} px={{ sm: 0 }}>
          <VStack spacing={14} mb={10} px={3}>
            <HabbitTextIcon width={300} height={100} />
            <VStack spacing={0}>
              <Text textStyle={'sectionHeaderPdpa'}>
                Habbit Points Membership
              </Text>
              <Text textStyle={'unlockItemTitle'}>
                Start collecting points now!
              </Text>
            </VStack>
          </VStack>
          <VStack spacing={10}>
            <Text textStyle={'sectionHeaderPdpaBold'} w={'100%'} px={5}>
              Perks Overview
            </Text>
            <Container maxW={'1150px'}>
              <RewardTiersTable data={REWARD_TIERS} />
            </Container>
          </VStack>
          {isPDPA && setPin && (
            <VStack w={'100%'} spacing={5} my={10} px={5}>
              <VStack spacing={0} w={'100%'}>
                <Text textStyle={'sectionHeaderPdpaBold'} w={'100%'}>
                  Points Redemption Pin
                </Text>
                <Text textStyle={'titlePdpa'} w={'100%'}>
                  Enter a 4-digit Pin Number for you to redeem points inside
                  your account
                </Text>
              </VStack>
              <VStack w={'100%'}>
                <HStack w={'100%'}>
                  <PinInput
                    size="lg"
                    type={'number'}
                    placeholder={''}
                    onChange={(e) => {
                      setPin(e)
                    }}
                  >
                    <PinInputField
                      {...pinInputFieldProps}
                      type={isShowPincode ? 'number' : 'password'}
                    />
                    <PinInputField
                      {...pinInputFieldProps}
                      type={isShowPincode ? 'number' : 'password'}
                    />
                    <PinInputField
                      {...pinInputFieldProps}
                      type={isShowPincode ? 'number' : 'password'}
                    />
                    <PinInputField
                      {...pinInputFieldProps}
                      type={isShowPincode ? 'number' : 'password'}
                    />
                  </PinInput>
                </HStack>
                <HStack w={'100%'}>
                  <Text
                    display={'inline-block'}
                    cursor={'pointer'}
                    onClick={() => {
                      setIsShowPincode(!isShowPincode)
                    }}
                  >
                    {!isShowPincode ? 'Show pin number' : 'Hide pin number'}
                  </Text>
                </HStack>
              </VStack>
              {error && (
                <Text w={'100%'} style={{ color: 'red' }}>
                  {error}
                </Text>
              )}
            </VStack>
          )}
          <VStack mt={isPDPA ? 10 : 0} spacing={5} px={5}>
            <HStack justifyContent={'space-between'} w={'100%'}>
              <Text textStyle={'sectionHeaderPdpaBold'} w={'100%'}>
                Terms & Conditions
              </Text>

              <ChevronDownIcon
                boxSize={9}
                bg={'red'}
                borderRadius={'md'}
                color={'white'}
              />
            </HStack>
            <VStack
              spacing={1}
              maxH={'400px'}
              overflowY={'scroll'}
              pr={5}
              className="pdpa"
            >
              <Text
                textStyle={'termsConditionsPdpa'}
                w={'100%'}
                dangerouslySetInnerHTML={{
                  __html: pdpa
                }}
              ></Text>
            </VStack>
          </VStack>
        </Container>
        <Container maxW={'1500px'} mt={10}>
          <HStack justifyContent={'end'}>
            <Button
              bg={'red'}
              px={10}
              py={9}
              borderRadius={'50px'}
              _hover={{
                bg: '#de4800cf'
              }}
              _focus={{
                bg: '#de4800cf'
              }}
              type="submit"
              isLoading={isLoading}
            >
              <Text textStyle={'sectionHeaderPdpaBoldSmall'}>
                Accept & Join
              </Text>
            </Button>
          </HStack>
        </Container>
      </ModalBody>
    </ModalContent>
  )
}

export default AdvocadoModalContent
