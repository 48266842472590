import React, { Fragment, useState } from 'react'
import { useAccount } from '@lib/context/account-context'
import { useRouter } from 'next/router'
import AvocadoSignupModal from '@components/modals/advocado/avocado-signup'
import AvocadoPDPAModal from '@components/modals/advocado/avocado-pdpa'
import RouterUrl from '@constants/router.constant'
import { medusaRequest } from '@lib/config'
import { AVOCADO_CONSTANT } from '@lib/constants'
import axios from 'axios'

const useAdvocado = () => {
  const { customer, avocado } = useAccount()
  const { push } = useRouter()
  const [isOpenSignup, setIsOpenSignup] = useState(false)
  const [isOpenPDPA, setIsOpenPDPA] = useState(false)

  const verifyAdvocado = () => {
    if (!customer) {
      push({
        pathname: '/auth/login'
      })
      return
    }
    if (avocado?.avocadoInfo) {
      if (avocado?.avocadoInfo?.pdpa === '0') {
        setIsOpenPDPA(true)
      } else {
        push(RouterUrl.REWARD_POINTS.ROOT)
      }
    } else {
      setIsOpenSignup(true)
    }
  }

  const loadAdvocadoSignupModal = () => {
    return (
      <Fragment>
        {avocado?.phone_code && avocado?.phone_num && (
          <AvocadoSignupModal
            isOpen={isOpenSignup}
            onCloseHandler={() => {
              setIsOpenSignup(false)
            }}
            phone_code={avocado?.phone_code}
            phone_num={avocado?.phone_num}
          />
        )}
      </Fragment>
    )
  }

  const loadAdvocadoPDPAModal = () => {
    return (
      <Fragment>
        {avocado?.token && (
          <AvocadoPDPAModal
            isOpen={isOpenPDPA}
            onCloseHandler={() => {
              setIsOpenPDPA(false)
            }}
          />
        )}
      </Fragment>
    )
  }

  const getAdvocadoToken = async () => {
    try {
      const { data } = await medusaRequest('GET', AVOCADO_CONSTANT.get_token)
      if (data.status == true) {
        return data.data
      }
      return null
    } catch (error) {
      throw new Error(error as any)
    }
  }

  const getAdvocadoUser = async (token: string, phone: string) => {
    try {
      const { parsePhoneNumberFromString } = await import('libphonenumber-js')

      const parsedNumber = parsePhoneNumberFromString(
        phone.indexOf('+') != -1 ? phone : '+' + phone
      )

      if (parsedNumber !== undefined) {
        let url = AVOCADO_CONSTANT.get_userinfo.replace(
          '${phone_code}',
          parsedNumber.countryCallingCode
        )
        url = url.replace('${phonenumber}', parsedNumber.nationalNumber)

        const { data } = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })

        console.log(data)

        if (data.status === 'success') {
          return data.data
        }
      }
      return null
    } catch (error) {
      throw new Error(error as any)
    }
  }

  return {
    verifyAdvocado,
    loadAdvocadoSignupModal,
    loadAdvocadoPDPAModal,
    getAdvocadoToken,
    getAdvocadoUser
  }
}

export default useAdvocado
