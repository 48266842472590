import React from 'react'
import {
  Text,
  TableContainer,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useResponsive } from '@common/hooks'

interface RewardTiersTablePropsType {
  data: any[]
}

const RewardTiersTable: React.FC<RewardTiersTablePropsType> = ({ data }) => {
  const THEAD_PY = { base: 1, sm: 2, md: 3, lg: 5 }
  const THEAD_PX = { base: 1, sm: 2, md: 3, lg: 5 }

  const TBODY_PY = { base: 1, sm: 2, md: 3, lg: 5 }
  const TBODY_PX = { base: 1, sm: 2, md: 3, lg: 5 }

  return (
    <TableContainer w={'100%'}>
      <Table variant="unstyled" border={'2px solid'} borderColor={'red'}>
        <Thead bg={'red'}>
          <Tr>
            <Th w={4 / 12} py={THEAD_PY} px={THEAD_PX}>
              <Text
                textStyle={'rewardTiersThead'}
                textAlign={'center'}
                textTransform={'none'}
              >
                Rewards
              </Text>
            </Th>
            <Th w={2 / 12} py={THEAD_PY} px={THEAD_PX}>
              <Text
                textStyle={'rewardTiersThead'}
                textAlign={'center'}
                textTransform={'none'}
              >
                Classic
              </Text>
            </Th>
            <Th w={2 / 12} py={THEAD_PY} px={THEAD_PX}>
              <Text
                textStyle={'rewardTiersThead'}
                textAlign={'center'}
                textTransform={'none'}
              >
                Silver
              </Text>
            </Th>
            <Th w={2 / 12} py={THEAD_PY} px={THEAD_PX}>
              <Text
                textStyle={'rewardTiersThead'}
                textAlign={'center'}
                textTransform={'none'}
              >
                Gold
              </Text>
            </Th>
            <Th w={2 / 12} py={THEAD_PY} px={THEAD_PX}>
              <Text
                textStyle={'rewardTiersThead'}
                textAlign={'center'}
                textTransform={'none'}
              >
                Platinum
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((rt: any) => {
            return (
              <Tr key={rt.rewardName} border={'2px solid'} borderColor={'red'}>
                <Td
                  border={'2px solid'}
                  borderColor={'red'}
                  py={TBODY_PY}
                  px={TBODY_PX}
                >
                  {rt.rewardBirthDay && (
                    <Text
                      textStyle={'rewardTiersTbodyText'}
                      textAlign={'center'}
                      color={'red'}
                    >
                      {rt.rewardBirthDay}
                    </Text>
                  )}
                  <Text
                    textStyle={'rewardTiersTbodyText'}
                    textAlign={'center'}
                    whiteSpace={'initial'}
                    w={{ md: '100%', xl: '446px' }}
                    my={'2px'}
                    margin={'auto'}
                  >
                    {rt.rewardName}
                  </Text>
                  {rt.rewardSubName && (
                    <Text
                      textStyle={'rewardTiersTbodyText'}
                      textAlign={'center'}
                    >
                      {rt.rewardSubName}
                    </Text>
                  )}
                </Td>
                {rt.rewardTypes.map((t: any, k: number) => {
                  return (
                    <Td
                      key={k}
                      border={'2px solid'}
                      borderColor={'red'}
                      py={TBODY_PY}
                      px={TBODY_PX}
                    >
                      {typeof t.value === 'string' ? (
                        <Text
                          textStyle={'rewardTiersTbodyText'}
                          textAlign={'center'}
                        >
                          {t.value}
                        </Text>
                      ) : typeof t.value === 'boolean' && t.value === true ? (
                        <Box
                          w={{ base: 2, sm: '15px', md: '28px' }}
                          h={{ base: 2, sm: '15px', md: '28px' }}
                          bg={'red'}
                          borderRadius={'50%'}
                          margin={'auto'}
                        ></Box>
                      ) : (
                        <Box
                          w={'32px'}
                          height={'32px'}
                          bg={'transparent'}
                          borderRadius={'50%'}
                          margin={'auto'}
                        ></Box>
                      )}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default RewardTiersTable
