import React from 'react'
import { IconPropsType } from './icon-props'
import { Icon } from '@chakra-ui/react'

export const HabbitTextIcon = ({
  width = 165,
  height = 45,
  color
}: IconPropsType) => {
  const fillText = color || '#DE4800'

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 166 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_5)">
        <path
          d="M26.9 2.99999C26.9 4.86999 26.9 10.86 26.9 16.14C26.9 18.53 26.9 20.82 26.9 22.6H5.12V2.73999H0.0599976V2.99999C0.159998 7.89999 0.0599976 27.47 0.0599976 38C0.0599976 41.45 0.0599976 44 0.0599976 44.91V45.16H5.12V44.9C5.12 41.29 5.12 36.9 5.12 32.99C5.12 29.85 5.12 26.99 5.12 24.92H26.9C26.9 30.63 26.9 38.29 26.9 42.44C26.9 43.6 26.9 44.44 26.9 44.9V45.15H32.08V2.73999H26.89L26.9 2.99999Z"
          fill={fillText}
        />
        <path
          d="M64.52 43.4C64.1329 43.5236 63.7227 43.5572 63.3207 43.4983C62.9186 43.4394 62.5353 43.2895 62.2 43.06C61.2 42.26 61.09 40.49 61.09 39.06C61.09 36.73 61.09 34.39 61.09 32.06C61.09 29.73 61.09 27.39 61.09 25.06C61.09 19.24 57.5 15.77 51.49 15.77C47.9734 15.8052 44.5683 17.0083 41.81 19.19L41.71 19.27V22.9L42.17 22.33C43.66 20.45 46.56 18.26 49.81 18.26C54.39 18.26 56.34 20.26 56.34 25.04C56.34 25.1 56.34 25.19 56.34 25.29C56.34 26.09 56.34 26.54 56.18 26.64C55.1385 27.1242 54.0345 27.4608 52.9 27.64C47.69 28.83 39 30.82 39 38.48C39 42.68 42.37 45.84 46.85 45.84C51.33 45.84 53.46 44.55 56.39 41.69C56.86 43.99 58.46 45.15 61.17 45.15C62.3865 45.1983 63.5968 44.9549 64.7 44.44L64.87 44.37L64.75 43.37L64.52 43.4ZM49.57 43.21C46.01 43.21 44.28 41.38 44.28 37.61C44.28 32.13 50.08 30.44 54.74 29.08L56.28 28.62V39.15C54.54 41.54 51.78 43.21 49.57 43.21Z"
          fill={fillText}
        />
        <path
          d="M84.12 16.25C82.1226 16.1546 80.139 16.6271 78.3992 17.613C76.6594 18.5988 75.2346 20.0575 74.29 21.82C74.29 16.6 74.29 14.1 74.29 11.59C74.29 8.92 74.29 6.24 74.29 0.26V0H69.67V0.26C69.77 5.41 69.67 28.26 69.67 39.26C69.67 42.09 69.67 44.14 69.67 44.9V45.15H70.6L70.67 45.07C70.96 44.75 71.3 44.35 71.67 43.85C72.04 43.35 72.84 42.43 73.67 41.49C75.48 43.59 78.3 45.9 84.01 45.9C92.51 45.9 97.01 38.07 97.01 30.33C97 23.52 93.63 16.25 84.12 16.25ZM83.12 18.44C90.38 18.44 91.9 25.04 91.9 30.58C91.9 35.64 90.81 44.14 83.56 44.14C77.56 44.14 74.29 39.46 74.29 30.95C74.29 24.8 77.59 18.44 83.12 18.44Z"
          fill={fillText}
        />
        <path
          d="M117.8 16.25C115.808 16.1603 113.83 16.6357 112.096 17.6212C110.362 18.6067 108.942 20.0623 108 21.82C108 16.6 108 14.1 108 11.59C108 8.92 108 6.24 108 0.26V0H103.31V0.26C103.41 5.41 103.31 28.26 103.31 39.26C103.31 42.09 103.31 44.14 103.31 44.9V45.15H104.24L104.32 45.07C104.61 44.75 104.94 44.35 105.32 43.85C105.7 43.35 106.48 42.43 107.32 41.49C109.13 43.59 111.96 45.9 117.66 45.9C126.17 45.9 130.66 38.07 130.66 30.33C130.7 23.52 127.31 16.25 117.8 16.25ZM116.8 18.44C124.05 18.44 125.57 25.04 125.57 30.58C125.57 35.64 124.49 44.14 117.23 44.14C111.23 44.14 107.96 39.46 107.96 30.95C108 24.8 111.28 18.44 116.81 18.44H116.8Z"
          fill={fillText}
        />
        <path d="M142.22 17.06H137.54V45.15H142.22V17.06Z" fill={fillText} />
        <path
          d="M140.16 2.92999C139.325 2.92999 138.523 3.26187 137.933 3.85261C137.342 4.44335 137.01 5.24456 137.01 6.07999C137.01 6.91542 137.342 7.71664 137.933 8.30738C138.523 8.89812 139.325 9.22999 140.16 9.22999C140.995 9.22999 141.797 8.89812 142.387 8.30738C142.978 7.71664 143.31 6.91542 143.31 6.07999C143.31 5.24456 142.978 4.44335 142.387 3.85261C141.797 3.26187 140.995 2.92999 140.16 2.92999Z"
          fill={fillText}
        />
        <path
          d="M165.31 43.52L165 43.59C164.226 43.8008 163.431 43.9249 162.63 43.96C158.27 43.96 157.91 40.31 157.91 36.24V19.13H164.26V17.06H157.91V8.60999L153.1 10.35L153.16 17.06H149V19.13H153.17V38.42C153.17 42.95 155.81 45.65 160.22 45.65C161.896 45.63 163.558 45.3534 165.15 44.83L165.33 44.77L165.31 43.52Z"
          fill={fillText}
        />
      </g>
      <defs>
        <clipPath id="clip0_5_5">
          <rect width="165.32" height="45.9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
