import NextHead from 'next/head'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { getHead } from '@lib/util/head'

type HeadProps = {
  title?: string
  description?: string | null
  image?: string | null
  link?: string
}

const Head: React.FC<HeadProps> = ({
  title,
  description,
  image,
  link: defaultLink
}) => {
  const [link, setLink] = useState<string | undefined>(defaultLink)
  const router = useRouter()

  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage
  } = getHead(router.asPath)

  const pageTitle = title ?? defaultTitle ?? 'HABBIT'
  const pageDescription = description ?? defaultDescription
  const pageImage = image ?? defaultImage

  const metaDescription = pageDescription ?? `${pageTitle} Page`

  useEffect(() => {
    if (typeof window !== 'undefined' && !link) {
      setLink(window.location.href)
    }
  }, [])

  return (
    <NextHead>
      <title>{pageTitle}</title>
      <meta itemProp="name" content={pageTitle} />
      <meta name="description" content={metaDescription} />
      <meta
        name="facebook-domain-verification"
        content="vqpy7scjkzcpxpzl859cif8v1xhbrj"
      />
      <meta itemProp="description" content={metaDescription} />
      {image && <meta itemProp="image" content={pageImage} />}
      <link rel="icon" href="/images/habbit-logo-transition.svg" />

      {/* https://developers.google.com/search/docs/crawling-indexing/canonicalization */}
      {/* In case, we are serve "products/product-a?color=green", the Google crawler still know the main page in "/products/product-a" */}
      {link && <link rel="canonical" href={link} />}

      <meta
        name="google-site-verification"
        content={process.env.NEXT_PUBLIC_GOOGLE_VERIFY_CONTENT ?? ''}
      />
    </NextHead>
  )
}

export default Head
