import { Modal, ModalOverlay } from '@chakra-ui/react'
import { AVOCADO_CONSTANT } from '@lib/constants'
import { useAccount } from '@lib/context/account-context'
import axios from 'axios'
import React, { useState } from 'react'

import qs from 'qs'
import AdvocadoModalContent from './advocado-modal-content'

interface AvocadoModalProps {
  isOpen: boolean
  onCloseHandler: () => void
}

export interface AvocadoSignUp {
  phone_code: string
  phone_num: string
  pin: string
}

const AvocadoPDPAModal = ({ isOpen, onCloseHandler }: AvocadoModalProps) => {
  const { avocado, setAvocado } = useAccount()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>('')

  const onCloseModal = () => {
    onCloseHandler()
  }

  const handleAccept = async (e: any) => {
    e.preventDefault()
    setError('')
    setIsLoading(true)
    try {
      const { data } = await axios.post(
        AVOCADO_CONSTANT.accept_pdpa,
        qs.stringify({
          countryCallingCode: avocado?.phone_code,
          phoneNumber: avocado?.phone_num,
          pdpa: '1'
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + avocado?.token
          }
        }
      )

      if (data.status === 'success') {
        setAvocado((pre: any) => ({
          ...pre,
          avocadoInfo: {
            ...pre.avocadoInfo,
            pdpa: '1'
          }
        }))
        setIsLoading(false)
        onCloseModal()
      } else {
        setIsLoading(false)
        setError(data.data)
      }
    } catch (e: any) {
      setError(e.message)
      setIsLoading(false)
      console.log(e.message)
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} size={'full'}>
        <ModalOverlay />
        <form onSubmit={handleAccept}>
          <AdvocadoModalContent
            avocado={avocado}
            isLoading={isLoading}
            error={error}
            onCloseModal={onCloseModal}
          />
        </form>
      </Modal>
    </>
  )
}

export default AvocadoPDPAModal
