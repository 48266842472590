'use client'
const crypto = require('crypto')

function sign(path) {
  if (
    process.env.NEXT_PUBLIC_OPTIMIZE_IMAGE_SECRET === undefined ||
    process.env.NEXT_PUBLIC_OPTIMIZE_IMAGE_SECRET === 'unsafe'
  ) {
    return `unsafe/${path}`
  }
  // See why we have to use public env while it should be private: https://github.com/vercel/next.js/issues/51842
  const hash = crypto
    .createHmac('sha1', process.env.NEXT_PUBLIC_OPTIMIZE_IMAGE_SECRET)
    .update(path)
    .digest('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
  return hash + '/' + path
}

function addCDN(path) {
  const cloudFront = process.env.NEXT_PUBLIC_CLOUD_FRONT
  const imageOptimizeUrl = process.env.NEXT_PUBLIC_OPTIMIZE_IMAGE_URL

  // if we have cloudfront, use it as CDN
  // if we don't, use image optimize url directly
  // if we don't have both, use the original path

  if (cloudFront) {
    return `${cloudFront}/${path}`
  }
  if (imageOptimizeUrl) {
    return `${imageOptimizeUrl}/${path}`
  }
  return path
}

export default function myImageLoader({ src, width, quality }) {
  const dimension = `${width}x0` // we don't care about height
  // check if src isn't has base url (meaning it's an internal image like /images/...).
  // We have to include the base url in the image path
  if (!src.includes('http')) {
    src = `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${src}`
  }

  let filters = {
    quality: quality || 80,
    format: 'webp'
  }

  // if image is svg, don't use webp
  if (src.includes('.svg')) {
    // remove format
    delete filters.format
    filters.raw = ''
  }

  const filterString = Object.keys(filters)
    .map((key) => `${key}(${filters[key]})`)
    .join(':')

  const baseSrc = encodeURI(
    `fit-in/${dimension}/smart/filters:${filterString}/${src}`
  )
  const signedSrc = sign(baseSrc)
  
  return addCDN(signedSrc)
}