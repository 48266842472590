import React, { useState } from 'react'
import { Modal, ModalOverlay } from '@chakra-ui/react'
import { AVOCADO_CONSTANT } from '@lib/constants'
import { useAccount } from '@lib/context/account-context'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import qs from 'qs'
import AdvocadoModalContent from './advocado-modal-content'

interface AvocadoModalProps {
  isOpen: boolean
  onCloseHandler: () => void
  phone_code: string
  phone_num: string
}

export interface AvocadoSignUp {
  phone_code: string
  phone_num: string
  pin: string
}

const AvocadoSignupModal = ({
  isOpen,
  onCloseHandler,
  phone_code,
  phone_num
}: AvocadoModalProps) => {
  const { avocado, handleGetAvocadoUser, customer } = useAccount()
  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, setValue } = useForm<AvocadoSignUp>({
    defaultValues: {
      phone_code: phone_code ?? '65',
      phone_num: phone_num ?? (customer?.phone as string)
    }
  })

  const [error, setError] = useState<string>('')

  const onCloseModal = () => {
    onCloseHandler()
  }

  const onSubmitHandler = async (value: AvocadoSignUp) => {
    setError('')
    setIsLoading(true)
    try {
      const { data } = await axios.post(
        AVOCADO_CONSTANT.create_customer,
        qs.stringify({
          countryCallingCode: value.phone_code,
          phoneNumber: value.phone_num,
          pin: value.pin
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + avocado?.token
          }
        }
      )

      if (data?.status == 'fail') {
        setError(data.data[0].msg ?? data.data)
        setIsLoading(false)
      }

      if (data?.status == 'success') {
        await handleAccept({
          phone_code: data?.data?.countryCallingCode ?? value.phone_code,
          phone_num: data?.data?.phoneNumber ?? value.phone_num
        })
      }
    } catch (e: any) {
      setError(e.message)
      setIsLoading(false)
      console.log(e.message)
    }
  }

  const handleAccept = async ({
    phone_code,
    phone_num
  }: {
    phone_code: string
    phone_num: string
  }) => {
    try {
      const { data } = await axios.post(
        AVOCADO_CONSTANT.accept_pdpa,
        qs.stringify({
          countryCallingCode: phone_code,
          phoneNumber: phone_num,
          pdpa: '1'
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + avocado?.token
          }
        }
      )

      if (data.status === 'success') {
        await handleGetAvocadoUser(avocado?.token || '')
        setIsLoading(false)
        onCloseModal()
      } else {
        setError(data.data)
        setIsLoading(false)
      }
    } catch (e: any) {
      setError(e.message)
      setIsLoading(false)
      console.log(e.message)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size={'full'}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <AdvocadoModalContent
          avocado={avocado}
          isLoading={isLoading}
          error={error}
          onCloseModal={onCloseModal}
          setPin={(val: string) => {
            setValue('pin', val)
          }}
        />
      </form>
    </Modal>
  )
}

export default AvocadoSignupModal
