export const REWARD_TIERS = [
  {
    rewardName: 'Tier Criteria Spending',
    rewardTypes: [
      {
        name: 'classic',
        value: '$0'
      },
      {
        name: 'silver',
        value: '$200'
      },
      {
        name: 'gold',
        value: '$500'
      },
      {
        name: 'platinum',
        value: '$1,000'
      }
    ]
  },
  {
    rewardName: 'Points earn',
    rewardTypes: [
      {
        name: 'classic',
        value: '1x'
      },
      {
        name: 'silver',
        value: '1.3x'
      },
      {
        name: 'gold',
        value: '1.5x'
      },
      {
        name: 'platinum',
        value: '1.8x'
      }
    ]
  },
  {
    rewardName: 'Rewards Redemption',
    rewardTypes: [
      {
        name: 'classic',
        value: true
      },
      {
        name: 'silver',
        value: true
      },
      {
        name: 'gold',
        value: true
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '$1 OFF Voucher',
    rewardTypes: [
      {
        name: 'classic',
        value: true
      },
      {
        name: 'silver',
        value: true
      },
      {
        name: 'gold',
        value: true
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '$5 OFF usable during your birthday',
    rewardSubName: 'month.',
    rewardBirthDay: 'Birthday Voucher',
    rewardTypes: [
      {
        name: 'classic',
        value: true
      },
      {
        name: 'silver',
        value: true
      },
      {
        name: 'gold',
        value: true
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '2x points earned during your birthday',
    rewardSubName: 'month',
    rewardBirthDay: 'Birthday Month',
    rewardTypes: [
      {
        name: 'classic',
        value: true
      },
      {
        name: 'silver',
        value: true
      },
      {
        name: 'gold',
        value: true
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '5% OFF Voucher',
    rewardTypes: [
      {
        name: 'classic',
        value: false
      },
      {
        name: 'silver',
        value: true
      },
      {
        name: 'gold',
        value: true
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '8% OFF Voucher',
    rewardTypes: [
      {
        name: 'classic',
        value: false
      },
      {
        name: 'silver',
        value: false
      },
      {
        name: 'gold',
        value: true
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '10% OFF Voucher',
    rewardTypes: [
      {
        name: 'classic',
        value: false
      },
      {
        name: 'silver',
        value: false
      },
      {
        name: 'gold',
        value: false
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  },
  {
    rewardName: '$10 OFF Voucher',
    rewardTypes: [
      {
        name: 'classic',
        value: false
      },
      {
        name: 'silver',
        value: false
      },
      {
        name: 'gold',
        value: false
      },
      {
        name: 'platinum',
        value: true
      }
    ]
  }
]
