import { Head } from '../../types/head'
import { HEADS } from '@constants/head.constant'

const EMPTY_HEAD: Head = {
  route: '',
  title: '',
  description: ''
}

export function getHead(route: Head['route']): Head {
  const head = HEADS.find((head) => head.route === route)
  if (!head) {
    return EMPTY_HEAD
  }
  return head
}
