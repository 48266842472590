import { Head } from '../types/head'

const AUTH_HEADS: Head[] = [
  {
    route: '/auth/login',
    title: 'Sign in',
    description: 'Sign in to your Habbit account.'
  },
  {
    route: '/auth/register',
    title: 'Sign up',
    description: 'Sign up to create your Habbit account.'
  },
  {
    route: '/auth/reset-password',
    title: 'Reset password',
    description: 'Reset your Habbit account password.'
  },
  {
    route: '/auth/verify-phone',
    title: 'Verify phone',
    description: 'Verify your phone number.'
  }
]

const ACCOUNT_HEADS: Head[] = [
  {
    route: '/account',
    title: 'Account',
    description: 'Overview of your account activity.'
  },
  {
    route: '/account/orders',
    title: 'Orders',
    description: 'Overview of your previous orders.'
  },
  {
    route: '/account/profile',
    title: 'Profile',
    description: 'View and edit your ACME profile.'
  },
  {
    route: '/account/addresses',
    title: 'Addresses',
    description: 'View your addresses.'
  }
]

export const HEADS: Head[] = [
  {
    route: '/',
    title: 'Home',
    description:
      'We are all about Happy Habitats.We are the knowledge bearers to simplifying your life with innovative home solutions. We are the happy movement, the cradle of living good, inspiring everyday simplicity and wellness from the moment you wake up, till you go to bed.'
  },
  {
    route: '/our-story',
    title: 'Our Story'
  },
  {
    route: '/read',
    title: 'Read'
  },
  {
    route: '/shop',
    title: 'Shop'
  },
  {
    route: '/deals',
    title: 'Deals'
  },
  {
    route: '/reward-points',
    title: 'Reward Points'
  },
  {
    route: '/faq-page',
    title: '/FAQ'
  },
  {
    route: '/partners-page',
    title: 'Partners'
  },
  {
    route: '/contact-us-page',
    title: 'Contact Us'
  },
  {
    route: '/cart',
    title: 'Shopping Bag',
    description: 'View your shopping bag'
  },
  {
    route: '/career-page',
    title: 'Careers'
  },
  {
    route: '/404',
    title: '404',
    description: 'Page not found'
  },
  {
    route: '/checkout',
    title: 'Checkout'
  },
  {
    route: '/terms-and-conditions-page',
    title: 'Terms and Conditions'
  },
  ...AUTH_HEADS,
  ...ACCOUNT_HEADS
]
